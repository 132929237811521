import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({ icone, text}) => {
    const button = (
        <div className='Icon_button' style={buttonStyles}>
            <FontAwesomeIcon icon={icone} style={{color: '#2967B0'}} />
        </div>
    )

    let component = (
        <a href={text}>
            {button}
        </a>
    )

    return component
}

const buttonStyles = {
    textAlign: 'center',
    fontSize: '30px',
    display: 'inline-block',
    marginTop: '2px',
    margin: '19px'
}

export default Icon
