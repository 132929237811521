import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset, faEnvelope, faSquare } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagram, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import Logo from '../images/Logo.svg'
import { Link } from 'gatsby'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <div id='social'>
        <img src={Logo} alt='Motorbooks' width={240}/>
        <h3 className='dark-blue-text'>Acompanhe nossas redes sociais</h3>
        <a href='https://www.facebook.com/motorbooksoficial/' target='_blank' rel='noreferrer'>
          <FontAwesomeIcon  icon={faFacebookSquare} />
        </a>
        <a href='https://www.instagram.com/motor_books/' target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
      <div id='about'>
        <div id='menu'>
          <div>
            <h3 className='dark-blue-text'>Empresa</h3>
            <ul className='blue-text'>
              <li><Link to='/about_us'>Sobre nós</Link></li>
              <li><Link to='/contact'>Contato</Link></li>
              {/* <li><Link to='/'>Política de Privacidade</Link></li> */}
            </ul>
          </div>
          {/* <div>
            <h3 className='dark-blue-text'>Plataforma</h3>
            <ul className='blue-text'>
              <li><Link to='/coming_soon'>Acessar</Link></li>
              <li><Link to='/coming_soon'>Criar Conta</Link></li>
            </ul>
          </div> */}
       </div>
        {/* <img
          src="//assets.pagseguro.com.br/ps-integration-assets/banners/pagamento/todos_estatico_550_100.gif"
          alt="Logotipos de meios de pagamento do PagSeguro"
          title="Este site aceita pagamentos com as principais bandeiras e bancos, saldo em conta PagSeguro e boleto."
        /> */}
      </div>
      <div id='contact'>
        <div>
          <h3 className='dark-blue-text'>Fale Conosco</h3>
          <p className='blue-text'>
            Horário de Atendimento<br />
            Segunda a sexta: 08:00h às 18:00h
          </p>
        </div>
        <div>
          <ul className='blue-text'>
            <li>
              <FontAwesomeIcon icon={faHeadset} className='dark-blue-text'/>
              (11) 98892-4847
            </li>
            <li>
              <FontAwesomeIcon icon={faEnvelope} className='dark-blue-text'/>
              <a href='mailto:atendimento@motorbooks.com.br'>atendimento@motorbooks.com.br</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faWhatsappSquare} style={{color: '#25D366' }} />
              <a href='https://api.whatsapp.com/send?phone=5511988924847' target='_blank' rel='noreferrer'>Contato via WhatsApp</a>
            </li>
          </ul>
        </div>
        <div>
          <small>MotorBooks - Todos os Direitos Reservados - Desenvolvido por <a href='https://bravion.dev' target='_blank' rel='noreferrer'>Bravion</a></small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
