import React from 'react'
import { Link } from 'gatsby'
import { faFacebook, faInstagramSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
// import Button from '../components/button'
import Icone from '../components/icon_button'
import Logo from '../images/Logo.svg'
import './nav.css'

const Nav = () => {
  return (
    <nav>
      <div id='logo'>
        <Link to='/'>
          <img src={Logo} alt='Logo Motorbooks' width={190} />
        </Link>
      </div>
      <div id='home'>
        <div id='icon'>
          <Icone text='https://www.facebook.com/profile.php?id=100075884769903' icone={faFacebook}></Icone>
          <Icone text='https://www.instagram.com/motor_books/' icone={faInstagramSquare}></Icone>
          <Icone text='mailto:atendimento@motorbooks.com.br' icone={faEnvelope}></Icone>
          <Icone text='https://api.whatsapp.com/send?phone=5511988924847' icone={faWhatsapp}></Icone>
        </div>

        <div id='nav-pages'>
          <ul>
            <li><Link to='/' activeClassName='active'>Início</Link></li>
            <li><AnchorLink to={`/#eng`}>Engenharia</AnchorLink></li>
            <li><AnchorLink to={`/#administrativo`}>Serviços</AnchorLink></li>
            <li><Link to='/about_us' activeClassName='active'>Sobre a Empresa</Link></li>
          </ul>
        </div>
      </div>
      {/* <div id='nav-account'>
        <ul>
          <li><Button text='Criar Conta' linkTo='/coming_soon' isDark /></li>
          <li><Link to='/coming_soon'>Acessar</Link></li>
        </ul>
      </div> */}
    </nav>
  )
}

export default Nav
