import React from 'react'
import Nav from './nav'
import Footer from '../layout/footer'
import './index.css'

const Layout = ({ children }) => {
  return (
    <div>
      <Nav />
      { children }
      <Footer />
    </div>
  )
}

export default Layout